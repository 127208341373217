<script>
  import AgregarReceta from "components/Multimedia/Recetas.svelte";
  import AgregarIngrediente from "components/Multimedia/Ingredientes.svelte";
  import AgregarCatalogo from "components/Multimedia/catalogos.svelte";

  let openTab = 1;

  function toggleTabs(tabNumber) {
    openTab = tabNumber;
  }
</script>

<div class="flex flex-wrap mt-32">
  <div class="w-full">
    <nav class="my-4 w-100 text-center font-bold text-2xl text-orange-500">
      Multimedia
    </nav>
    <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-missing-attribute -->
      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <a
          class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal {openTab ===
          1
            ? 'text-white bg-brown-500'
            : 'text-orange-500 bg-white'}"
          on:click={() => toggleTabs(1)}
        >
          Agregar Recetas
        </a>
      </li>
      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-missing-attribute -->
        <a
          class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal {openTab ===
          2
            ? 'text-white bg-brown-500'
            : 'text-orange-500 bg-white'}"
          on:click={() => toggleTabs(2)}
        >
          Agregar Ingredientes
        </a>
      </li>
      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-missing-attribute -->
        <a
          class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal {openTab ===
          3
            ? 'text-white bg-brown-500'
            : 'text-orange-500 bg-white'}"
          on:click={() => toggleTabs(3)}
        >
          Agregar Catálogos
        </a>
      </li>
      <!-- Asegúrate de que los otros elementos de la lista estén comentados o eliminados -->
    </ul>
    <div
      class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
    >
      <div class="px-4 py-5 flex-auto">
        <div class="tab-content tab-space">
          <div class={openTab === 1 ? "block" : "hidden"}>
            <AgregarReceta />
          </div>
          <div class={openTab === 2 ? "block" : "hidden"}>
            <AgregarIngrediente />
          </div>
          <div class={openTab === 3 ? "block" : "hidden"}>
            <AgregarCatalogo />
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-white w-full mb-6 shadow-lg rounded flex justify-center items-center p-4 font-bold"
    >
      Para agregar archivos, crea un archivo ZIP con una carpeta llamada img que
      contenga las imágenes que deseas subir. <br /> Si prefieres agregar
      videos, nombra la carpeta video y coloca tus videos dentro. <br /> Puedes incluir
      ambas carpetas en el mismo ZIP si necesitas subir tanto imágenes como videos.
    </div>
  </div>
</div>

<style>
</style>
