
<script>
  import { Router, Route } from "svelte-routing";

  // components for this layout

  // pages for this layout
  import Login from "views/auth/Login.svelte";
  import Register from "views/auth/Register.svelte";

   

</script>

<div>
  <main>
    <section class="relative w-full h-full py-40 min-h-screen flex items-center">
      <div
        class="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
      ></div>
      <Router url="auth">
        <Route path="login" component="{Login}" />
        <Route path="register" component="{Register}" />
      </Router>
    </section>
  </main>
</div>