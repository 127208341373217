<!-- App.svelte -->
<script>
  import { onMount } from "svelte";
  import { Router, Route } from "svelte-routing";
  import axios from "axios";

  // Admin Layout
  import Admin from "layouts/Admin.svelte";
  // Auth Layout
  import Auth from "layouts/Auth.svelte";
  // No Layout Pages
  import Index from "views/Index.svelte";

  export let url = "";

  onMount(() => {
    const token = sessionStorage.getItem("token");
    const currentPath = window.location.pathname;

    if (token) {
      if (!currentPath.startsWith("/admin")) {
        window.location.href = "/admin/dashboard";
      }
    } else {
      if (currentPath !== "/auth/login") {
        window.location.href = "/auth/login";
      }
    }
  });
</script>

<Router {url}>
  <!-- admin layout -->
  <Route path="admin/*" component={Admin} />
  <!-- auth layout -->
  <Route path="auth/*" component={Auth} />
  <!-- no layout pages -->
  <Route path="/" component={Index} />
</Router>
