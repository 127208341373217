<script>
	import Medidas from './../../views/admin/catalogs/Medidas.svelte';
  import axios from "axios";
  // cargar modal
  import ModalArchivos from "components/ModalArchivos.svelte";
  import { onMount } from "svelte";
  import Multimedia from "views/admin/Archivos.svelte";
  let ModalArchivosNum = 0;
  function ModalContentOpen() {
    ModalArchivosNum = 1;
  }
  function closeModal() {
    ModalArchivosNum = 0;
  }
  // Cargar todos os catálogos
  let supermarket = [];
  const catalogoSupermarket = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Accept-Language": "es",
      };
      const response = await axios.get(
        `https://prod.honey-dates.com/api/v1/catalog/type/4`,
        { headers }
      );
      supermarket = response.data.data;
      console.log(supermarket);
    } catch (error) {
      console.error("Error al obtener los ítems del catálogo:", error);
    }
  };
  let unidadMedida = [];
  const catalogoUnidadesMedida = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Accept-Language": "es",
      };
      const response = await axios.get(
        `https://prod.honey-dates.com/api/v1/catalog/type/3`,
        { headers }
      );
      unidadMedida = response.data.data;
      console.log(unidadMedida);
    } catch (error) {
      console.error("Error al obtener los ítems del catálogo:", error);
    }
  };
  // Definimos el token de autorización
  const token = "eyJhbGciOiJIUzUxMiJ9.eyJyb2xlcyI6WyJST0xFX1VTRVIiXSwibmFtZSI6IkpvcmdlIENhbGRlcm9uIiwiaWQiOjcsImVtYWlsT3JQaG9uZSI6ImNhbGRlcm9uLmpvcmdlNEBnbWFpbC5jb20iLCJzdWIiOiI3IiwiaWF0IjoxNzEyNzEwNTExLCJleHAiOjE3NDQyNDY1MTF9.bvUq4kgZMK6Slv8NtS8ER4lL-qLsPsBcWpagpvyNopcee7vLK46k_eQrTngE2aAMw6HAWm2K372ftQZBuuqotg";

  // Estado para almacenar los datos del formulario
  let form = [
    {
      code: "",
      conversionFactor: 0,
      gramEquivalence: 0,
      idDefaultMeasurement: 0,
      idSupermarketCategory: 0,
      imageUrl: "",
      isDislike: false,
      name: "",
      nutrients: [
        {
          nutrient: "Calorias",
          quantity: 0,
          idNutrient: 1,
        },
        {
          nutrient: "Carbohidratos",
          quantity: 0,
          idNutrient: 2,
        },
        {
          nutrient: "Grasas",
          quantity: 0,
          idNutrient: 3,
        },
        {
          nutrient: "Proteinas",
          quantity: 0,
          idNutrient: 4,
        },
        {
          nutrient: "Colesterol",
          quantity: 0,
          idNutrient: 5,
        },
        {
          nutrient: "Hierro",
          quantity: 0,
          idNutrient: 6,
        },
        {
          nutrient: "Sodio",
          quantity: 0,
          idNutrient: 7,
        },
      ],
      percentageMerma: 0,
      subNutrients: [
        {
          nutrient: "Grasas Saturadas",
          quantity: 0,
          idSubNutrient: 4,
        },
        {
          nutrient: "Grasas Trans",
          quantity: 0,
          idSubNutrient: 3,
        },
        {
          nutrient: "Fibra",
          quantity: 0,
          idSubNutrient: 1,
        },
        {
          nutrient: "Grasas Insaturadas",
          quantity: 0,
          idSubNutrient: 5,
        },
        {
          nutrient: "Azucar",
          quantity: 0,
          idSubNutrient: 2,
        },
      ],
      translateSave: [
        {
          column: "name",
          language: "en",
          value: "",
        },
      ],
    },
  ];

  let successMessage = "";
  let errorMessage = "";

  // Función para manejar el envío del formulario
  // Función para manejar el envío del formulario
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Convertir los valores a números enteros
      form[0].idDefaultMeasurement = parseInt(form[0].idDefaultMeasurement);
      form[0].idSupermarketCategory = parseInt(form[0].idSupermarketCategory);

      form[0].imageUrl = `/media/ingredients/img/${form[0].imageUrl}`;
      console.log(form);
      const response = await axios.post(
        "https://prod.honey-dates.com/api/v1/ingredients",
        form,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Manejamos la respuesta
      console.log("Respuesta del servidor:", response);
      successMessage = "¡El ingrediente se agregó correctamente!";
      clearForm();
      setTimeout(() => {
        successMessage = "";
      }, 5000);
    } catch (error) {
      console.error("Error al agregar el ingrediente:", error.message);
      errorMessage =
        "Hubo un error al agregar el ingrediente. Por favor, inténtalo de nuevo.";
      setTimeout(() => {
        errorMessage = "";
      }, 5000);
    }
  };

  function clearForm() {
    form = [
      {
        code: "",
        conversionFactor: 0,
        gramEquivalence: 0,
        idDefaultMeasurement: 0,
        idSupermarketCategory: 0,
        imageUrl: "",
        isDislike: false,
        name: "",
        nutrients: [
          {
            nutrient: "Calorias",
            quantity: 0,
            idNutrient: 1,
          },
          {
            nutrient: "Carbohidratos",
            quantity: 0,
            idNutrient: 2,
          },
          {
            nutrient: "Grasas",
            quantity: 0,
            idNutrient: 3,
          },
          {
            nutrient: "Proteinas",
            quantity: 0,
            idNutrient: 4,
          },
          {
            nutrient: "Colesterol",
            quantity: 0,
            idNutrient: 5,
          },
          {
            nutrient: "Hierro",
            quantity: 0,
            idNutrient: 6,
          },
          {
            nutrient: "Sodio",
            quantity: 0,
            idNutrient: 7,
          },
        ],
        percentageMerma: 0,
        subNutrients: [
          {
            nutrient: "Grasas Saturadas",
            quantity: 0,
            idSubNutrient: 4,
          },
          {
            nutrient: "Grasas Trans",
            quantity: 0,
            idSubNutrient: 3,
          },
          {
            nutrient: "Fibra",
            quantity: 0,
            idSubNutrient: 1,
          },
          {
            nutrient: "Grasas Insaturadas",
            quantity: 0,
            idSubNutrient: 5,
          },
          {
            nutrient: "Azucar",
            quantity: 0,
            idSubNutrient: 2,
          },
        ],
        translateSave: [
          {
            column: "name",
            language: "en",
            value: "",
          },
        ],
      },
    ];
  }

  onMount(() => {
    catalogoSupermarket();
    catalogoUnidadesMedida();
  });
</script>

<button
  class="mb-2 px-4 py-2 rounded-lg text-white font-semibold bg-brown-500"
  on:click={ModalContentOpen}>Agregar Multimedia</button
>
<form on:submit|preventDefault={handleSubmit} style="max-width: 600px;"  class="max-w-lg mx-auto">
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label
        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        for="code"
      >
        Código
      </label>
      <input
        class="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
        id="code"
        type="text"
        placeholder="Código, ejemplo ABA-000-000 "
        bind:value={form[0].code}
        required
      />
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label
        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        for="name"
      >
        Nombre
      </label>
      <input
        class="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
        id="name"
        type="text"
        placeholder="Nombre"
        bind:value={form[0].name}
        required
      />
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label
        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        for="name"
      >
        Nombre en Ingles
      </label>
      <input
        class="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
        id="name"
        type="text"
        placeholder="Nombre en Ingles"
        bind:value={form[0].translateSave[0].value}
        required
      />
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label
        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        for="conversionFactor"
      >
        Factor de Conversión
      </label>
      <input
        class="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        id="conversionFactor"
        type="number"
        placeholder="Factor de Conversión"
        bind:value={form[0].conversionFactor}
        required
      />
    </div>
  </div>

  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3">
      <label
        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        for="gramEquivalence"
      >
        Equivalencia en Gramos
      </label>
      <input
        class="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
        id="gramEquivalence"
        type="number"
        placeholder="Equivalencia en Gramos"
        bind:value={form[0].gramEquivalence}
        required
      />
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label
        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        for="idDefaultMeasurement"
      >
        Medida Predeterminada
      </label>
      <select
        class="appearance-none block w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        id="idDefaultMeasurement"
        bind:value={form[0].idDefaultMeasurement}
        required
      >
        {#each unidadMedida as medida}
          <option class="px-2" value={medida.id}>{medida.name}</option>
        {/each}
      </select>
    </div>
  </div>

  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3">
      <label
        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        for="idSupermarketCategory"
      >
        Categoría de Supermercado
      </label>
      <select
        class="appearance-none block w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        id="idSupermarketCategory"
        bind:value={form[0].idSupermarketCategory}
        required
      >
      {#each supermarket as categoria}
      <option class="px-2" value={categoria.id}>{categoria.name}</option>
    {/each}
      </select>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label
        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        for="imageUrl"
      >
        URL de la Imagen
      </label>
      <input
        class="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        id="imageUrl"
        type="text"
        placeholder="URL de la Imagen"
        bind:value={form[0].imageUrl}
        required
      />
    </div>
  </div>

  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3">
      <label
        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        for="percentageMerma"
      >
        Porcentaje de Merma
      </label>
      <input
        class="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        id="percentageMerma"
        type="number"
        placeholder="Porcentaje de Merma"
        bind:value={form[0].percentageMerma}
        required
      />
    </div>
  </div>

  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3">
      <label
        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        for="isDislike"
      >
        ¿Es Dislike?
      </label>
      <select
        class="appearance-none block w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        id="isDislike"
        on:change={(e) => (form[0].isDislike = e.target.value === "true")}
        required
      >
        <option value="true">Sí</option>
        <option value="false">No</option>
      </select>
    </div>
  </div>
  <hr />
  <h3 class="mx-4 font-bold my-4 text-red-500">Nutrientes</h3>
  <hr />
  {#each form[0].nutrients as nutrient}
    <div class="flex flex-wrap -mx-3 mb-6">
      <div class="w-full md:w-1/2 px-3">
        <label
          class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          for={nutrient.nutrient}
        >
          {nutrient.nutrient}
        </label>
        <input
          class="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
          id={nutrient.nutrient}
          type="number"
          placeholder="Cantidad de {nutrient.nutrient}"
          bind:value={nutrient.quantity}
          required
        />
      </div>
    </div>
  {/each}

  <hr />
  <h3 class="mx-4 font-bold my-4 text-red-500">Sub Nutrientes</h3>
  <hr />
  {#each form[0].subNutrients as subNutrient}
    <div class="flex flex-wrap -mx-3 mb-6">
      <div class="w-full md:w-1/2 px-3">
        <label
          class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          for={subNutrient.nutrient}
        >
          {subNutrient.nutrient}
        </label>
        <input
          class="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
          id={subNutrient.nutrient}
          type="number"
          placeholder="Cantidad de {subNutrient.nutrient}"
          bind:value={subNutrient.quantity}
          required
        />
      </div>
    </div>
  {/each}

  <div class="flex justify-center w-full">
    <button
      type="submit"
      class="bg-brown-500 text-white font-bold py-2 px-4 rounded-lg"
      >Agregar Ingrediente</button
    >
  </div>

  {#if successMessage}
    <div class="text-green-600 w-full text-center mt-4">{successMessage}</div>
  {/if}
  {#if errorMessage}
    <div class="text-red-600 w-full text-center mt-4">{errorMessage}</div>
  {/if}
</form>

{#if ModalArchivosNum !== 0}
  <ModalArchivos on:close={closeModal}>
    <Multimedia />
  </ModalArchivos>
{/if}
