<script>
  let date = new Date().getFullYear();
</script>

<footer class="relative bg-blueGray-200 pt-8 pb-6 mt-auto">
  <hr class="my-6 border-blueGray-300" />
  <div class="flex flex-wrap items-center md:justify-between justify-center">
    <div class="w-full md:w-4/12 px-4 mx-auto text-center">
      <div class="text-sm text-blueGray-500 font-semibold py-1">
        Copyright © {date} Sofnomic.
  </div>
    </div>
  </div>
</footer>
