<!-- Modal.svelte -->
<script>
    import { createEventDispatcher } from 'svelte';
  
    const dispatch = createEventDispatcher();
  
    function closeModal() {
      dispatch('close');
    }
  
    // Evita que los clics dentro del modal se propaguen al contenedor externo
    function stopPropagation(event) {
      event.stopPropagation();
    }
  </script>
  
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="fixed top-0 left-0 w-full h-full flex justify-center items-center" style="background-color: rgba(255, 255, 255, 0.5); z-index: 1000!important;" on:click={closeModal}>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div style="max-height: 800px; overflow: auto;" class="bg-white rounded-lg p-8  relative shadow-2xl" on:click|stopPropagation>
        <button class="absolute top-0 right-0 p-2" on:click={closeModal}>
            <svg class="w-8 h-8 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
          
      <div class="w-full flex flex-col justify-center">
        <slot></slot>
      </div>
    </div>
  </div>

  