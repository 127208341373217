<script>
  import ApiAgregar  from "components/categorias/agregar.svelte";

  import Editar      from "components/categorias/editar.svelte";

  let openTab = 2;

  function toggleTabs(tabNumber) {
    openTab = tabNumber;
  }
</script>

<div style="max-width: 1080px;" class="flex flex-wrap mt-32 mx-auto">
  <div class="w-full mx-auto">
    <nav class="my-4 w-100 text-center font-bold text-2xl text-orange-500">
      Componentes de Receta
    </nav>
    <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-missing-attribute -->
        <a
          class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal {openTab ===
          1
            ? 'text-white bg-brown-500'
            : 'text-orange-500 bg-white'}"
          on:click={() => toggleTabs(1)}
        >
          <i class="fas fa-space-shuttle text-base mr-1"></i> Nuevo
        </a>
      </li>
      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-missing-attribute -->
        <a
          class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal {openTab ===
          2
            ? 'text-white bg-brown-500'
            : 'text-orange-500 bg-white'}"
          on:click={() => toggleTabs(2)}
        >
          <i class="fas fa-cog text-base mr-1"></i> Listado
        </a>
      </li>

    </ul>
    <div
      class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
    >
      <div class="px-4 py-5 flex-auto">
        <div class="tab-content tab-space">
          <div class={openTab === 1 ? "block" : "hidden"}>
            <ApiAgregar NumCatalog={14} />
          </div>
          <div class={openTab === 2 ? "block" : "hidden"}>
            <Editar idCatalog={14} />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
