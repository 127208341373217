<!-- Modal.svelte -->
<script>
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  function closeModalArchvos() {
    dispatch("close");
  }

  // Evita que los clics dentro del modal se propaguen al contenedor externo
  function stopPropagation(event) {
    event.stopPropagation();
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="fixed top-0 left-0 w-full h-full flex justify-center items-center"
  style="background-color: rgba(255, 255, 255, 0.5); z-index: 1000!important;"
  on:click={closeModalArchvos}
>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div
    style="max-height: 800px; overflow: auto;"
    class="bg-white rounded-lg p-8 relative shadow-2xl"
    on:click|stopPropagation
  >
    <button class="absolute top-0 right-0 p-2" on:click={closeModalArchvos}>
      <svg
        class="w-8 h-8 text-black"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </button>

    <div class="w-full flex flex-col justify-center">
      <slot />
    </div>
  </div>
</div>

<!--
  // cargar modal
  import ModalArchivos from "components/ModalArchivos.svelte";
  import Multimedia    from "views/admin/Archivos.svelte";
  let ModalArchivosNum  = 0;
  function ModalContentOpen() {
    ModalArchivosNum  =1;
  }
  function closeModalArchivos() {
    ModalArchivosNum  = 0;
  } 
-->

<!--
    <button class="my-4 px-4 py-2 rounded-lg text-white font-semibold bg-brown-500" on:click={ModalContentOpen}>Agregar Multimedia</button> 
  -->

<!--
     {#if ModalArchivosNum !==  0 }
<ModalArchivos on:close={closeModalArchivos}>
  <Multimedia/>
</ModalArchivos>
{/if} 
-->
