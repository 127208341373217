<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";

  let user = "";
  let password = "";
  let error = "";
  let showPassword = false;

  // Token adicional para los encabezados
  const additionalToken = "eyJhbGciOiJIUzUxMiJ9.eyJyb2xlcyI6WyJST0xFX1VTRVIiXSwibmFtZSI6IkpvcmdlIENhbGRlcm9uIiwiaWQiOjcsImVtYWlsT3JQaG9uZSI6ImNhbGRlcm9uLmpvcmdlNEBnbWFpbC5jb20iLCJzdWIiOiI3IiwiaWF0IjoxNzEyNzEwNTExLCJleHAiOjE3NDQyNDY1MTF9.bvUq4kgZMK6Slv8NtS8ER4lL-qLsPsBcWpagpvyNopcee7vLK46k_eQrTngE2aAMw6HAWm2K372ftQZBuuqotg";

  const login = async () => {
    try {
      const response = await fetch("https://prod.honey-dates.com/api/v1/backoffice/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${additionalToken}`,  // Agregar el token adicional en el encabezado
          "Accept-Language": "es",
        },
        body: JSON.stringify({ emailOrPhone: user, password: password }),
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.code;  // Aquí usamos el campo 'code' como token
        sessionStorage.setItem('token', token); 
        navigate("/admin/dashboard");
      } else {
        error = "Usuario o Contraseña incorrectos";
      }
    } catch (err) {
      error = "Ocurrió un error al iniciar sesión";
    }
  };

  onMount(() => {
    const token = sessionStorage.getItem('token');

    if (token) {
      navigate("/admin/dashboard");
    }
  });
</script>

<div class="container mx-auto px-4 h-full">
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full lg:w-4/12 px-4">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
        <div class="flex-auto px-4 lg:px-10 py-10">
          <form>
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user">Usuario</label>
              <input
                bind:value={user}
                type="text"
                class="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Usuario"
              />
            </div>

            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="password">Contraseña</label>
              <div class="relative">
                <input
                  value={password}
                  type={showPassword ? "text" : "password"}
                  class="border-0 px-3 py-3 pr-10 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Contraseña"
                  on:input={(event) => password = event.target.value}
                />
                <span class="absolute inset-y-0 right-0 pr-3 flex items-center text-blueGray-600">
                  <button
                    type="button" 
                    class="p-1 focus:outline-none focus:ring"
                    on:click={() => showPassword = !showPassword}
                  >
                    <i class="{showPassword ? 'far fa-eye-slash' : 'far fa-eye'}"></i>
                  </button>
                </span>
              </div>
            </div>
            
            {#if error}
              <div class="text-red-500 mb-3">{error}</div>
            {/if}

            <div class="text-center mt-6">
              <button
                type="button"
                on:click={login}
                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
              >
                Ingresar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
