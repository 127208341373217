<script>
  import { Router, Route, Link } from "svelte-routing";

  // Components for this layout
  import AdminNavbar from "components/Navbars/AdminNavbar.svelte";
  import Sidebar     from "components/Sidebar/Sidebar.svelte";
  import HeaderStats from "components/Headers/HeaderStats.svelte";
  import FooterAdmin from "components/Footers/Footer.svelte";
  

  // Pages for this layout
  import Dashboard    from "views/admin/Dashboard.svelte";
  import Recetas      from "views/admin/Recetas.svelte";
  import Ingredientes from "views/admin/Ingredientes.svelte";
  import Multimedia    from "views/admin/Archivos.svelte";

  import Alergias    from "views/admin/catalogs/Alergias.svelte";
  import CategoriasRecetas    from "views/admin/catalogs/CategoriasRecetas.svelte";
  import CategoriaSupermercado    from "views/admin/catalogs/CategoriaSupermercado.svelte";
  import ComponentesReceta    from "views/admin/catalogs/ComponentesReceta.svelte";
  import CuadrantesPlato    from "views/admin/catalogs/CuadrantesPlato.svelte";
  import DificultadReceta    from "views/admin/catalogs/DificultadReceta.svelte";
  import Medidas    from "views/admin/catalogs/Medidas.svelte";
  import NivelCocinaUsuario    from "views/admin/catalogs/NivelCocinaUsuario.svelte";
  import RegimenNutricional    from "views/admin/catalogs/RegimenNutricional.svelte";
  import SubCategoriasRecetas    from "views/admin/catalogs/SubCategoriasRecetas.svelte";
  import TiempoComida    from "views/admin/catalogs/TiempoComida.svelte";
  import TiempoComidaCreaciones    from "views/admin/catalogs/TiempoComidaCreaciones.svelte";
  import TiempoDuracion    from "views/admin/catalogs/TiempoDuracion.svelte";
  import TiposCorte    from "views/admin/catalogs/TiposCorte.svelte";
  import TiposPlatoReceta    from "views/admin/catalogs/TiposPlatoReceta.svelte";
  import TiposProductoReceta    from "views/admin/catalogs/TiposProductoReceta.svelte";
  import Utensilios    from "views/admin/catalogs/Utensilios.svelte";
  import ImagenesCarrousel    from "views/admin/catalogs/ImagenesCarrousel.svelte";
  import VersionesFront    from "views/admin/catalogs/VersionesFront.svelte";
  import VersionesPresentacion    from "views/admin/catalogs/VersionesPresentacion.svelte";
</script>

<div>
  <Sidebar />
  <div class="relative  md:ml-64 bg-blueGray-100">
    <AdminNavbar />
    <HeaderStats />
    <div class="px-4  md:px-10 mx-auto w-full -m-24">
      <Router url="admin">
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/recetas" component={Recetas} />
        <Route path="/ingredientes" component={Ingredientes} />
        <Route path="/archivos" component={Multimedia} />
        
        <Route path="/Alergias" component={Alergias} />
        <Route path="/CategoriasRecetas" component={CategoriasRecetas} />
        <Route path="/CategoriaSupermercado" component={CategoriaSupermercado} />
        <Route path="/ComponentesReceta" component={ComponentesReceta} />
        <Route path="/CuadrantesPlato" component={CuadrantesPlato} />
        <Route path="/DificultadReceta" component={DificultadReceta} />
        <Route path="/Medidas" component={Medidas} />
        <Route path="/NivelCocinaUsuario" component={NivelCocinaUsuario} />
        <Route path="/RegimenNutricional" component={RegimenNutricional} />
        <Route path="/SubCategoriasRecetas" component={SubCategoriasRecetas} />
        <Route path="/TiempoComida" component={TiempoComida} />
        <Route path="/TiempoComidaCreaciones" component={TiempoComidaCreaciones} />
        <Route path="/TiempoDuracion" component={TiempoDuracion} />
        <Route path="/TiposCorte" component={TiposCorte} />
        <Route path="/TiposPlatoReceta" component={TiposPlatoReceta} />
        <Route path="/TiposProductoReceta" component={TiposProductoReceta} />
        <Route path="/Utensilios" component={Utensilios} />
        <Route path="/ImagenesCarrousel" component={ImagenesCarrousel} />
        <Route path="/VersionesFront" component={VersionesFront} />
        <Route path="/VersionesPresentacion" component={VersionesPresentacion} />
      
      </Router>
      <FooterAdmin />
    </div>
  </div>
</div>
