<script>
  import Alergias from "./../../views/admin/catalogs/Alergias.svelte";
  import axios from "axios";
  import { onMount } from "svelte";
  import Modal from "components/Modal.svelte";
  // cargar modal
  import ModalArchivos from "components/ModalArchivos.svelte";
  import Multimedia from "views/admin/Archivos.svelte";
  let ModalArchivosNum = 0;
  function ModalContentOpen() {
    ModalArchivosNum = 1;
  }
  function closeModalArchivos() {
    ModalArchivosNum = 0;
  }

  let ingredients = [];
  let currentPage = 1;
  let itemsPerPage = 5;
  let maxPage = 0;
  let newTranslation;
  let alergias = [];
  let searchValue = "";
  const token = "eyJhbGciOiJIUzUxMiJ9.eyJyb2xlcyI6WyJST0xFX1VTRVIiXSwibmFtZSI6IkpvcmdlIENhbGRlcm9uIiwiaWQiOjcsImVtYWlsT3JQaG9uZSI6ImNhbGRlcm9uLmpvcmdlNEBnbWFpbC5jb20iLCJzdWIiOiI3IiwiaWF0IjoxNzEyNzEwNTExLCJleHAiOjE3NDQyNDY1MTF9.bvUq4kgZMK6Slv8NtS8ER4lL-qLsPsBcWpagpvyNopcee7vLK46k_eQrTngE2aAMw6HAWm2K372ftQZBuuqotg";

  let ModalContent = 0;
  let selectedItem = null;

  // Función para cerrar el modal
  function closeModal() {
    selectedItem = null;
  }
  function handleSearch(event) {
    searchValue = event.target.value;
    loadIngredients(); // Vuelve a cargar los ingredientes con el nuevo valor de búsqueda
  }

  // carga de alergias
  // Inicializar alergiasCompletas como un array vacío
  let alergiasCompletas = [];
  const obtenerAlergias = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Accept-Language": "es",
      };
      const response = await axios.get(
        `https://prod.honey-dates.com/api/v1/catalog/type/2`,
        { headers }
      );

      alergiasCompletas = response.data.data;
      console.log(alergiasCompletas);
    } catch (error) {
      console.error("Error al obtener los ítems del catálogo:", error);
    }
  };
  let supermarket = [];
  const catalogoSupermarket = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Accept-Language": "es",
      };
      const response = await axios.get(
        `https://prod.honey-dates.com/api/v1/catalog/type/4`,
        { headers }
      );
      supermarket = response.data.data;
      console.log(supermarket);
    } catch (error) {
      console.error("Error al obtener los ítems del catálogo:", error);
    }
  };
  let unidadMedida = [];
  const catalogoUnidadesMedida = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Accept-Language": "es",
      };
      const response = await axios.get(
        `https://prod.honey-dates.com/api/v1/catalog/type/3`,
        { headers }
      );
      unidadMedida = response.data.data;
      console.log(unidadMedida);
    } catch (error) {
      console.error("Error al obtener los ítems del catálogo:", error);
    }
  };

  // Cargar ingredientes
  const loadIngredients = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Accept-Language": "es",
      };

      const response = await axios.get(
        `https://prod.honey-dates.com/api/v1/ingredients?page=${currentPage}&size=${itemsPerPage}&isDislike=false&val=${searchValue}`,
        { headers }
      );
      maxPage = response.data.data.last;
      ingredients = response.data.data.content;

      // Verificar si algún ingrediente tiene nutrientes vacíos y agregar los nutrientes predeterminados
      ingredients.forEach((ingredient) => {
        if (ingredient.nutrients.length === 0) {
          const defaultNutrients = [
            { idNutrient: 1, nutrient: "Calorias", quantity: 0 },
            { idNutrient: 2, nutrient: "Carbohidratos", quantity: 0 },
            { idNutrient: 3, nutrient: "Grasas", quantity: 0 },
            { idNutrient: 4, nutrient: "Proteinas", quantity: 0 },
            { idNutrient: 5, nutrient: "Colesterol", quantity: 0 },
            { idNutrient: 6, nutrient: "Hierro", quantity: 0 },
            { idNutrient: 7, nutrient: "Sodio", quantity: 0 },
          ];
          ingredient.nutrients = defaultNutrients;
        }

        // Verificar si algún ingrediente tiene subnutrientes vacíos y agregar los subnutrientes predeterminados
        if (ingredient.subNutrients.length === 0) {
          const defaultSubNutrients = [
            { idSubNutrient: 1, subNutrient: "Fibra", quantity: 0 },
            { idSubNutrient: 2, subNutrient: "Azúcar", quantity: 0 },
            { idSubNutrient: 3, subNutrient: "Grasas Trans", quantity: 0 },
            { idSubNutrient: 4, subNutrient: "Grasas Saturadas", quantity: 0 },
            {
              idSubNutrient: 5,
              subNutrient: "Grasas Insaturadas",
              quantity: 0,
            },
          ];
          ingredient.subNutrients = defaultSubNutrients;
        }

        if (ingredient.translate === null || ingredient.translate === "null") {
          ingredient.translate = [
            {
              language: "en",
              value: "Sin Traducción",
              column: "name",
            },
          ];
        } else if (typeof ingredient.translate === "string") {
          // convierte el string en objeto de js
          ingredient.translate = JSON.parse(ingredient.translate);
        }
      });
      if (ingredients.defaultMeasurement === null) {
        ingredients.defaultMeasurement.id = "";
      }

      console.log(ingredients);
    } catch (error) {
      console.error("Error:", error.message);
      ingredients = null;
    }
  };

  // Paginación
  const nextPage = async () => {
    currentPage++;
    await loadIngredients();
  };

  const previousPage = async () => {
    if (currentPage > 1) {
      currentPage--;
      await loadIngredients();
    }
  };

  // Eliminar ingrediente
  const deleteIngredient = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const url = `https://prod.honey-dates.com/api/v1/ingredients/${selectedItem.id}`;
      const response = await axios.delete(url, { headers });
      loadIngredients();
      closeModal();
    } catch (err) {
      console.error(err);
    }
  };
  // Editar ingrediente
  const editIngredient = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const url = `https://prod.honey-dates.com/api/v1/ingredients/${selectedItem.id}`;

      // Convertir los valores de cantidad de nutrientes a números
      selectedItem.nutrients.forEach((nutrient) => {
        nutrient.quantity = parseFloat(nutrient.quantity) || 0; // Convertir a número o 0 si no se puede convertir
      });

      // Convertir los valores de cantidad de subnutrientes a números
      selectedItem.subNutrients.forEach((subNutrient) => {
        subNutrient.quantity = parseFloat(subNutrient.quantity) || 0; // Convertir a número o 0 si no se puede convertir
      });

      // Obtener solo el nombre del archivo de la URL de la imagen
      const nombreArchivo = obtenerNombreArchivo(selectedItem.imageUrl);

      // Agregar el prefijo correcto a la URL de la imagen
      selectedItem.imageUrl = `/media/ingredients/img/${nombreArchivo}`;

      selectedItem.translateSave = selectedItem.translate;

      console.log(selectedItem);

      // Realizar la solicitud PUT a la API
      const response = await axios.put(url, selectedItem, { headers });
      console.log(response);
      loadIngredients();
      closeModal();
    } catch (err) {
      console.error(err);
    }
  };

  function obtenerNombreArchivo(url) {
    const partesURL = url.split("/");
    const nombreArchivo = partesURL.pop();
    return nombreArchivo;
  }
  // Agregar alergia desde el dropdown
  async function agregarAlergia() {
    const alergiaSelect = document.getElementById("alergia-select");
    const alergiaId = alergiaSelect.value;

    // Verificar si la alergia ya está agregada
    if (!selectedItem.allergies.find((alergia) => alergia.id === alergiaId)) {
      console.log(alergiaId);
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        // Configurar el objeto de datos a enviar en la solicitud POST
        const data = {
          idCatalog: alergiaId, // Usar alergiaId en lugar de idCatalog
          idIngredient: selectedItem.id,
        };

        // Realizar la solicitud POST
        const response = await axios.post(
          "https://prod.honey-dates.com/api/v1/ingredient-allergies/create",
          data,
          { headers }
        );

        console.log(response);
        // Verificar si la solicitud se realizó con éxito
        if (response.status === 200) {
          // Si la solicitud se realizó con éxito, agregar la alergia a selectedItem
          const alergiaSeleccionada =
            alergiaSelect.options[alergiaSelect.selectedIndex].text;
          selectedItem.allergies.push({
            id: alergiaId,
            name: alergiaSeleccionada,
          });
          // Actualizar selectedItem para forzar una actualización en Svelte
          selectedItem = { ...selectedItem };
          loadIngredients();
        } else {
          console.error("Error al agregar la alergia");
        }
      } catch (error) {
        console.error("Error al agregar la alergia:", error);
      }
    }
  }

  async function eliminarAlergiaExistente(idCatalog) {
    // Cambiar el parámetro a idCatalog
    const index = selectedItem.allergies.findIndex(
      (alergia) => alergia.idCatalog === idCatalog // Cambiar la comparación aquí
    );
    if (index !== -1) {
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
          "Accept-Language": "es",
        };

        // Realizar la solicitud DELETE con el token de autorización en los encabezados
        const response = await axios.delete(
          `https://prod.honey-dates.com/api/v1/ingredient-allergies/delete`,
          {
            headers,
            data: {
              idCatalog: idCatalog,
              idIngredient: selectedItem.id,
            },
          }
        );
        console.log(idCatalog, selectedItem.id, response);

        // Verificar si la solicitud se realizó con éxito
        if (response.status === 200) {
          // Si la solicitud se realizó con éxito, eliminar la alergia de selectedItem
          selectedItem.allergies.splice(index, 1);
          // Actualizar selectedItem para forzar una actualización en Svelte
          selectedItem = { ...selectedItem };
          loadIngredients();
        } else {
          console.error("Error al eliminar la alergia");
        }
      } catch (error) {
        console.error("Error al eliminar la alergia:", error);
      }
    }
  }

  onMount(() => {
    loadIngredients();
    obtenerAlergias();
    catalogoSupermarket();
    catalogoUnidadesMedida();
  });
</script>

<div class="mb-4">
  <label for="searchInput" class="block text-gray-700 font-bold mb-2">
    Buscar ingrediente:
  </label>
  <input
    type="text"
    id="searchInput"
    class="p-2 border rounded-lg"
    bind:value={searchValue}
    on:input={handleSearch}
  />
</div>
<!-- Renderizado de ingredientes -->
{#if ingredients !== null && ingredients.length > 0}
  {#each ingredients as ingredient (ingredient.id)}
    <div
      style="max-width: 1200px;"
      class="border rounded-lg overflow-hidden shadow-2xl my-2 flex flex-col bg-blueGray-200 border border-blueGray-300 overflow-x-auto mx-auto"
    >
      <!-- Nombre del ingrediente -->
      <h3 class="text-2xl font-bold mx-auto text-blueGray-800">
        {ingredient.name}
      </h3>

      <div class="flex justify-center">
        <div class="p-4 flex">
          <!-- Información nutricional -->
          <div class="mx-4">
            <h3 class="text-lg font-bold mb-2">Información nutricional:</h3>
            <h3 class="font-bold">Nutrientes:</h3>
            {#each ingredient.nutrients as nutrient}
              <p>{nutrient.nutrient}: {nutrient.quantity}</p>
            {/each}
            <h3 class="font-bold">Sub Nutrientes:</h3>
            {#each ingredient.subNutrients as nutrient}
              <p>{nutrient.subNutrient}: {nutrient.quantity}</p>
            {/each}
          </div>

          <!-- Detalles adicionales -->
          <div style="margin-right: 20px;">
            <h3 class="text-lg font-bold mb-4">Detalles adicionales:</h3>
            <p>Código: {ingredient.code}</p>
            <p>ID: {ingredient.id}</p>
            <h3 class="font-bold">Alergias:</h3>
            {#if ingredient.allergies.length > 0}
              {#each ingredient.allergies as allergie}
                <p>{allergie.name}</p>
              {/each}
            {:else}
              <p>Sin Alergias</p>
            {/if}
            <h3 class="font-bold">Nombre de medida:</h3>
            <p>
              {#if ingredient.defaultMeasurement}
                {ingredient.defaultMeasurement === null
                  ? "Sin Medida"
                  : ingredient.defaultMeasurement.name}
              {:else}
                Sin Medida
              {/if}
            </p>
            <h3 class="font-bold">Porcentaje de Merma:</h3>
            <p>{ingredient.percentageMerma}</p>
            <h3 class="font-bold">Equivalencia en gramos:</h3>
            <p>{ingredient.gramEquivalence}</p>
            <h3 class="font-bold">Categoría de supermercado:</h3>
            <p>{ingredient.supermarketCategory.name}</p>
            {#if ingredient.translate && ingredient.translate !== "null"}
              {#each ingredient.translate as translation}
                <h3 class="font-bold">Traducciones:</h3>
                <h2>{translation.language} : {translation.value}</h2>
              {/each}
            {/if}
          </div>
        </div>
        <div class="m-auto">
          <img
            src={ingredient.imageUrl}
            alt="{ingredient.name} no tiene imagen"
            style="object-fit: contain; max-height: 300px; border-radius: 50px; margin: auto;"
            class="p-3"
          />

          <div class="w-full flex justify-center flex-wrap">
            <button
              on:click={() => {
                ModalContent = 3;
                obtenerAlergias();
                selectedItem = { ...ingredient };
              }}
              class="m-2 px-6 py-2 bg-brown-500 rounded-lg font-bold text-white"
            >
              Alergias
            </button>
            <button
              on:click={() => {
                ModalContent = 1;
                selectedItem = { ...ingredient };
              }}
              class="m-2 px-6 py-2 bg-lightBlue-500 rounded-lg font-bold text-white"
            >
              Editar
            </button>

            <button
              on:click={() => {
                ModalContent = 2;
                selectedItem = { ...ingredient };
              }}
              class="m-2 px-6 py-2 bg-red-500 rounded-lg font-bold text-white"
            >
              Eliminar
            </button>
          </div>
        </div>
      </div>
    </div>
  {/each}
  <!-- Paginación -->
  {#if maxPage !== true}
    <div class="flex justify-center gap-6">
      <button
        class="px-6 py-2 bg-brown-500 text-white font-bold m-2 rounded-lg"
        on:click={previousPage}
        disabled={currentPage === 1}
      >
        Anterior
      </button>
      <button
        class="px-6 py-2 bg-brown-500 text-white font-bold m-2 rounded-lg"
        on:click={nextPage}
      >
        Siguiente
      </button>
    </div>
  {/if}
{:else}
  <p>No se encontraron productos con el nombre o código especificado.</p>
{/if}

<!-- Modal para editar/eliminar -->
{#if selectedItem}
  <Modal on:close={closeModal}>
    {#if ModalContent === 1}
      <!-- Editar -->
      <button
        style="width: max-content;"
        class="my-4 px-4 py-2 rounded-lg text-white font-semibold bg-brown-500"
        on:click={ModalContentOpen}>Agregar Multimedia</button
      >
      <form
        style="width: 1000px;"
        on:submit|preventDefault={editIngredient}
        class="mx-auto p-6 bg-gray-100 rounded-lg shadow-md justify-between flex flex-wrap"
      >
        <div class="mb-4" style="width: 50%;">
          <label for="code" class="block text-gray-700 font-bold mb-2">
            Código:
          </label>
          <input
            style="border: 2px solid rgb(137, 118, 106);"
            type="text"
            id="code"
            class="p-2 border rounded-lg"
            bind:value={selectedItem.code}
          />
        </div>
        <div class="mb-4" style="width: 50%;">
          <label
            for="conversionFactor"
            class="block text-gray-700 font-bold mb-2"
          >
            Factor de Conversión:
          </label>
          <input
            style="border: 2px solid rgb(137, 118, 106);"
            type="text"
            id="conversionFactor"
            class="p-2 border rounded-lg"
            bind:value={selectedItem.conversionFactor}
          />
        </div>
        <div class="mb-4" style="width: 50%;">
          <label
            for="defaultMeasurement"
            class="block text-gray-700 font-bold mb-2"
          >
            Medida Predeterminada
          </label>
          <select
            style="border: 2px solid rgb(137, 118, 106);"
            class="p-2 border rounded-lg"
            id="idDefaultMeasurement"
            bind:value={selectedItem.idDefaultMeasurement}
            required
          >
            {#each unidadMedida as medida}
              <option class="px-2" value={medida.id}>{medida.name}</option>
            {/each}
          </select>
        </div>
        <div class="mb-4" style="width: 50%;">
          <label
            for="gramEquivalence"
            class="block text-gray-700 font-bold mb-2"
          >
            Equivalencia en Gramos:
          </label>
          <input
            style="border: 2px solid rgb(137, 118, 106);"
            type="text"
            id="gramEquivalence"
            class="p-2 border rounded-lg"
            bind:value={selectedItem.gramEquivalence}
          />
        </div>
        <div class="mb-4" style="width: 50%;">
          <label for="id" class="block text-gray-700 font-bold mb-2">
            ID:
          </label>
          <input
            style="border: 2px solid rgb(137, 118, 106);"
            type="text"
            id="id"
            class="p-2 border rounded-lg"
            bind:value={selectedItem.id}
            disabled
          />
        </div>
        <div class="mb-4" style="width: 50%;">
          <label for="imageUrl" class="block text-gray-700 font-bold mb-2">
            Url de Imagen:
          </label>
          <input
            style="border: 2px solid rgb(137, 118, 106);"
            type="text"
            id="imageUrl"
            class="p-2 border rounded-lg"
            placeholder="Ingresa el nombre de la imagen"
            value={selectedItem.imageUrl
              ? selectedItem.imageUrl.split("/").pop()
              : ""}
            on:input={(event) => {
              selectedItem.imageUrl = event.target.value;
            }}
          />
        </div>
        <div class="mb-4" style="width: 50%;">
          <label for="isDislike" class="block text-gray-700 font-bold mb-2">
            Tiene Dislike:
          </label>
          <select
            style="border: 2px solid rgb(137, 118, 106);"
            id="isDislike"
            class="p-2 border rounded-lg"
            bind:value={selectedItem.isDislike}
          >
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </div>
        <div class="mb-4" style="width: 50%;">
          <label for="name" class="block text-gray-700 font-bold mb-2">
            Nombre:
          </label>
          <input
            style="border: 2px solid rgb(137, 118, 106);"
            type="text"
            id="name"
            class="p-2 border rounded-lg"
            bind:value={selectedItem.name}
          />
        </div>
        <div class="mb-4" style="width: 50%;">
          <label
            for="percentageMerma"
            class="block text-gray-700 font-bold mb-2"
          >
            percentageMerma:
          </label>
          <input
            style="border: 2px solid rgb(137, 118, 106);"
            type="text"
            id="percentageMerma"
            class="p-2 border rounded-lg"
            bind:value={selectedItem.percentageMerma}
          />
        </div>
        <div class="mb-4" style="width: 50%;">
          <label
            for="supermarketCategory"
            class="block text-gray-700 font-bold mb-2"
          >
            Categoria de Supermercado:
          </label>
          <select
            style="border: 2px solid rgb(137, 118, 106);"
            class="p-2 border rounded-lg"
            id="idSupermarketCategory"
            bind:value={selectedItem.idSupermarketCategory}
            required
          >
            {#each supermarket as catalogSm}
              <option class="px-2" value={catalogSm.id}>{catalogSm.name}</option>
            {/each}
          </select>
        </div>
        <div class="mb-4" style="width: 50%;">
          <h3 class="block text-gray-700 font-bold mb-2">Traducción:</h3>
          <input
            style="border: 2px solid rgb(137, 118, 106);"
            id="translate_language"
            class="p-2 border rounded-lg"
            bind:value={selectedItem.translate[0].value}
          />
        </div>
        <div class="mb-4 px-2">
          <h3 class="block text-gray-700 font-bold mb-2">Nutrientes:</h3>
          <div class="flex flex-wrap">
            {#each selectedItem.nutrients as nutrient}
              <div
                style="width: 50%;"
                class="my-2 flex justify-between items-center"
              >
                <label class="m-2 font-bold" for={nutrient.nutrient}>
                  {nutrient.nutrient}:
                </label>
                <input
                  style="border: 2px solid rgb(137, 118, 106);"
                  type="number"
                  step="0.0001"
                  id={nutrient.nutrient}
                  class="m-3 p-2 border rounded-md"
                  bind:value={nutrient.quantity}
                />
              </div>
            {/each}
          </div>
        </div>
        <div class="mb-4 px-2">
          <h3 class="block text-gray-700 font-bold mb-2">Sub Nutrientes:</h3>
          <div class="flex flex-wrap">
            {#each selectedItem.subNutrients as subNutrient}
              <div style="width: 50%;" class="my-2 flex justify-between">
                <label class="m-2 font-bold" for={subNutrient.subNutrient}>
                  {subNutrient.subNutrient}:
                </label>
                <input
                  style="border: 2px solid rgb(137, 118, 106);"
                  type="number"
                  step="0.0001"
                  id={subNutrient.nutrient}
                  class="m-3 p-2 border rounded-md"
                  bind:value={subNutrient.quantity}
                />
              </div>
            {/each}
          </div>
        </div>

        <button
          type="submit"
          class="mx-auto p-3 bg-brown-500 text-white font-bold rounded-md shadow-lg mb-4"
        >
          Guardar Cambios
        </button>
      </form>
    {:else if ModalContent === 2}
      <!-- Eliminar -->
      <div>
        <h2 class="text-xl mb-4">
          ¿Estás seguro de que deseas eliminar {selectedItem.name}?
        </h2>
        <form on:submit|preventDefault={deleteIngredient}>
          <div class="my-4 flex justify-around items-center">
            <button
              class="bg-blueGray-600 text-white font-bold py-2 px-4 rounded-lg"
              style="height: fit-content;"
              type="button"
              on:click={closeModal}
            >
              Cancelar
            </button>
            <button
              class="bg-red-500 text-white font-bold py-2 px-4 rounded-lg"
              style="height: fit-content;"
              type="submit"
            >
              Eliminar
            </button>
          </div>
        </form>
      </div>
    {:else if ModalContent === 3}
      <!-- alergias -->
      <div style="min-width: 500px;">
        <div class="mb-4 w-full">
          <h3 class="block text-gray-700 font-bold mb-2">Alergias:</h3>
          <div class="flex gap-4 items-center">
            <select
              id="alergia-select"
              class="py-2 px-6 border rounded-md mr-2"
            >
              {#each alergiasCompletas as alergia}
                <option class="px-2" value={alergia.id}>{alergia.name}</option>
              {/each}
            </select>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
              on:click={agregarAlergia}
              class="p-2 bg-brown-500 text-white font-bold rounded-md cursor-pointer"
            >
              Agregar
            </div>
          </div>
        </div>

        {#if selectedItem.allergies.length > 0}
          {#each selectedItem.allergies as allergie}
            <div class="flex items-center">
              <h3>{allergie.name}</h3>
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <div
                on:click={() => eliminarAlergiaExistente(allergie.idCatalog)}
                class="text-red-500 font-bold cursor-pointer ml-2"
              >
                X
              </div>
            </div>
          {/each}
        {:else}
          <p>No tiene alergias.</p>
        {/if}
      </div>
    {/if}
  </Modal>
{/if}

{#if ModalArchivosNum !== 0}
  <ModalArchivos on:close={closeModalArchivos}>
    <Multimedia />
  </ModalArchivos>
{/if}
