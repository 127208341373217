<script>
	import Catalogos from './../Multimedia/catalogos.svelte';
  import { link } from "svelte-routing";
  // library for creating dropdown menu appear on click
  import { createPopper } from "@popperjs/core";

  // core components

  let dropdownPopoverShow = false;

  let btnDropdownRef;
  let popoverDropdownRef;

  const toggleDropdown = (event) => {
    event.preventDefault();
    if (dropdownPopoverShow) {
      dropdownPopoverShow = false;
    } else {
      dropdownPopoverShow = true;
      createPopper(btnDropdownRef, popoverDropdownRef, {
        placement: "bottom-start",
      });
    }
  };
</script>

<div>
  <a
    class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
    href="#pablo"
    bind:this={btnDropdownRef}
    on:click={toggleDropdown}
  >
    <i
      class="fas fa-tools mr-2 text-sm text-blueGray-300 hover:text-blueGray-700"
    ></i>
    Catálogos
  </a>
  <div
    bind:this={popoverDropdownRef}
    class="bg-brown-500 text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg min-w-48 {dropdownPopoverShow
      ? 'block'
      : 'hidden'}"
  >
    <a
      use:link
      href="/admin/Alergias"
      class="font-bold text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-brown-500 hover:text-blueGray-300"
    >
      Alergias
    </a>
    <a
      use:link
      href="/admin/CategoriasRecetas"
      class="font-bold text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-brown-500 hover:text-blueGray-300"
    >
      Categorias de Recetas
    </a>
    <a
      use:link
      href="/admin/CategoriaSupermercado"
      class="font-bold text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-brown-500 hover:text-blueGray-300"
    >
      Categoria de Supermercado
    </a>
    <a
      use:link
      href="/admin/ComponentesReceta"
      class="font-bold text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-brown-500 hover:text-blueGray-300"
    >
      Componentes de Receta
    </a>
    <a
      use:link
      href="/admin/CuadrantesPlato"
      class="font-bold text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-brown-500 hover:text-blueGray-300"
    >
      Cuadrantes de Plato
    </a>
    <a
      use:link
      href="/admin/DificultadReceta"
      class="font-bold text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-brown-500 hover:text-blueGray-300"
    >
      Dificultad de Receta
    </a>
    <a
      use:link
      href="/admin/Medidas"
      class="font-bold text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-yello-500 hover:text-blueGray-300"
    >
      Medidas
    </a>
    <a
      use:link
      href="/admin/NivelCocinaUsuario"
      class="font-bold text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-brown-500 hover:text-blueGray-300"
    >
      Nivel de Cocina de Usuario
    </a>
    <a
      use:link
      href="/admin/RegimenNutricional"
      class="font-bold text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-brown-500 hover:text-blueGray-300"
    >
      Regimen Nutricional
    </a>
    <a
      use:link
      href="/admin/SubCategoriasRecetas"
      class="font-bold text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-brown-500 hover:text-blueGray-300"
    >
      SubCategorias de Recetas
    </a>
    <a
      use:link
      href="/admin/TiempoComida"
      class="font-bold text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-brown-500 hover:text-blueGray-300"
    >
      Tiempo de Comida
    </a>
    <a
      use:link
      href="/admin/TiempoComidaCreaciones"
      class="font-bold text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-brown-500 hover:text-blueGray-300"
    >
      Tiempo de Comida de Creaciones
    </a>
    <a
      use:link
      href="/admin/TiempoDuracion"
      class="font-bold text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-brown-500 hover:text-blueGray-300"
    >
      Tiempo de Duracion
    </a>
    <a
      use:link
      href="/admin/TiposCorte"
      class="font-bold text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-brown-500 hover:text-blueGray-300"
    >
      Tipos de Corte
    </a>
    <a
      use:link
      href="/admin/TiposPlatoReceta"
      class="font-bold text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-brown-500 hover:text-blueGray-300"
    >
      Tipos de Plato de Receta
    </a>
    <a
      use:link
      href="/admin/TiposProductoReceta"
      class="font-bold text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-brown-500 hover:text-blueGray-300"
    >
      Tipos de Producto de Receta
    </a>
    <a
      use:link
      href="/admin/Utensilios"
      class="font-bold text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-brown-500 hover:text-blueGray-300"
    >
      Utensilios
    </a>
    <a
    use:link
    href="/admin/ImagenesCarrousel"
    class="font-bold text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-brown-500 hover:text-blueGray-300"
  >
  Imágenes del Carrousel
  </a>
  <a
    use:link
    href="/admin/VersionesFront"
    class="font-bold text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-brown-500 hover:text-blueGray-300"
  >
  Versiones del Front
  </a>
  <a
    use:link
    href="/admin/VersionesPresentacion"
    class="font-bold text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-brown-500 hover:text-blueGray-300"
  >
  Versiones de Presentación
  </a>
    <div class="h-0 mx-4 my-2 border border-solid border-blueGray-100" />
  </div>
</div>
